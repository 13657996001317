const resources = {
  'en-US': {
    translation: {
      appDownload1: 'Available on iOS and',
      appDownload2: 'Android devices',
      brainyBackground: 'Brainy Background',
      tip: 'Tip',
      download: 'Download',
      vroomComplete: 'We Did It!',
      expandDownloads_one: 'Expand for {{count}} language',
      expandDownloads_other: 'Expand for {{count}} languages',
      closeDownloads: 'Close downloads',
      contentAreas: 'Content Areas',
      language: 'Language',
      age: 'Age',
      downloadAllTipSheets: 'Download All {{filter}} Tip Sheets',
      sms: {
        headerTitle: 'Vroom by Text',
        headerSubtitle: "Boost your child's learning with fast and fun tips available by text.",
        headerText: 'Helping them learn now gets them ready for school, friends, and life.',
        getStarted:
          'With Vroom by Text you can turn any moment into a brain building moment. No need for yet another app or dipping into your data plan! You can even register multiple children to a single phone number.',
        availableLanguages: 'Available in English or Spanish.',
        messageRates:
          'By submitting this number, I consent to Vroom sending me weekly brain building tips via automated text messages.  Msg & Data Rates May Apply. Text STOP to cancel.',
        infoText1:
          'Every week, we deliver a fun Vroom Tip™️ to you and your child right to your phone.',
        infoText2: 'You’ll get tips chosen specifically for your child’s age.',
        infoText3:
          'With each short activity, you teach your child the life skills that help them thrive.',
        successMessage:
          'Thank you for signing up for Vroom by Text! Expect to receive a text soon to complete your registration.',
        errorMessage:
          "We're sorry, something went wrong. Our web services team has been alerted. Please fill out the form again.",
      },
    },
  },
  es: {
    translation: {
      appDownload1: 'Disponible en dispositivos',
      appDownload2: 'iOS y Android',
      brainyBackground: 'Cómo ayuda esto al aprendizaje de tu niño',
      tip: 'Actividad',
      download: 'Descargar',
      vroomComplete: '¡Hazlo!',
      expandDownloads_one: 'Expandir para {{count}} idioma',
      expandDownloads_other: 'Expandir para {{count}} idiomas',
      closeDownloads: 'Cerrar descargas',
      contentAreas: 'Áreas de contenido',
      language: 'Idioma',
      age: 'Edad',
      downloadAllTipSheets: 'Descargar todas las hojas de consejos en {{filter}}',
      sms: {
        headerTitle: 'Vroom por SMS',
        headerSubtitle:
          'Estimula el aprendizaje de tu niño con actividades rápidas y divertidas disponible por SMS.',
        headerText: 'Ayudarlo a aprender ahora lo prepara para la escuela, los amigos y la vida.',
        getStarted:
          'Vroom por SMS convierte cualquier momento en un momento para desarrollar el cerebro. ¡No hace falta usar otra aplicación o recurrir a tu plan de datos! Puedes incluso registrar a varios niños en un solo número telefónico.',
        availableLanguages:
          '¡Regístrese para recibir un mensaje de texto semanal con consejos sobre cómo desarrollar la mente de su hijo!',
        messageRates:
          'Al enviar este número, doy consentimiento a Vroom para que me envíe consejos para desarrollar la mente de mi(s) hijo(s) en forma de mensajes de texto automatizados. Cargos por mensajes o datos pueden aplicar. Envía STOP para parar de recibir mensajes.',
        infoText1:
          'Cada semana, enviamos a tu teléfono una actividad Vroom divertida para ti y tu niño.',
        infoText2: 'Recibirás actividades seleccionadas para la edad de tu niño.',
        infoText3:
          'Cada actividad Vroom se basa en la ciencia del cerebro. Con cada actividad corta, le enseña a su hijo las habilidades para la vida que los ayudan a prosperar.',
        successMessage: 'Usted se ha registrado con éxito para los textos semanales con Vroom!',
        errorMessage:
          'Lo sentimos. Algo salió mal. Nuestro equipo de servicios web ha sido alertado.',
      },
    },
  },
}

export { resources }
