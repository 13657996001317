import localFont from 'next/font/local'

const omnes = localFont({
  src: [
    {
      path: '../static/fonts/omnes/Omnes-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../static/fonts/omnes/Omnes-RegularItalic.woff',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../static/fonts/omnes/Omnes-Medium.woff',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../static/fonts/omnes/Omnes-MediumItalic.woff',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../static/fonts/omnes/Omnes-Semibold.woff',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../static/fonts/omnes/Omnes-SemiboldItalic.woff',
      weight: '600',
      style: 'italic',
    },
  ],
  variable: '--omnes-font',
  fallback: ['sans-serif'],
})

export const omnesClassNames = [omnes.variable, omnes.className].join(' ')
