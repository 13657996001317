import React from 'react'
import { Context as ResponsiveContext } from 'react-responsive'

const ssrWidth = 1024 // to match util/viewport.js

const ResponsiveProvider = ({ children }) => {
  if (typeof window === 'undefined') {
    // during static site generation and server-side rendering, assume a large viewport
    return (
      <ResponsiveContext.Provider value={{ width: ssrWidth }}>
        {children}
      </ResponsiveContext.Provider>
    )
  } else {
    return <>{children}</>
  }
}

export default ResponsiveProvider
