import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { resources } from '@util/i18n/resources'

const detectionOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'language',
  lookupCookie: 'i18next',

  cookieMinutes: 15,
  caches: ['cookie'],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-US',
    detection: detectionOptions,

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
