import React from 'react'
import Router from 'next/router'
import dynamic from 'next/dynamic'
import TagManager from 'react-gtm-module'

import { omnesClassNames as fontClassNames } from '@util/omnesFont'
import '@util/i18n'

import ResponsiveProvider from '@components/responsive/responsive_provider'

import '@pages/app.scss'
import '@styles/forms.scss'
import '@styles/normalize.scss'
import '@components/layout/background_color_changer.scss'
import '@components/layout/default_layout.scss'
import '@components/layout/plain_layout.scss'
import '@components/layout/standard_layout.scss'
import '@components/blocks/components/cross_fade.scss'
import '@components/blocks/hero_block.scss'
import '@components/blocks/text_block.scss'
import '@components/blocks/video_carousel_block.scss'
import '@components/icons/animated/leaves.scss'
import '@components/block.scss'
import '@components/footer.scss'
import '@components/header.scss'

import '@styles/vendor/slick.scss'
import '@styles/vendor/slick-theme.scss'
import 'swiper/css'

const useGoogleTagManager = () =>
  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
  }, [])

const NoSsrLoading = dynamic(() => import('@components/icons/loading'), { ssr: false })

export default function App({ Component, pageProps }) {
  useGoogleTagManager()

  if (typeof window !== 'undefined' && Router.isFallback) {
    return <NoSsrLoading />
  }

  return (
    <div className={fontClassNames}>
      <ResponsiveProvider>
        <Component {...pageProps} />
      </ResponsiveProvider>
    </div>
  )
}
